<template>
    <b-card class="position-relative" title="Paramétrer un template">
        <b-tabs>
            <b-tab title="Ajouter">
                <b-form-group id="selectMedia" label="Label : " label-for="selectMedia">
                    <b-form-input v-model="label" required></b-form-input>
                </b-form-group>

                <b-form-group id="selectMedia" label="Media : " label-for="selectMedia">
                    <b-form-select id="selectMedia" v-model="selectedMediaAdd" :options="availableMedias"
                        required></b-form-select>
                </b-form-group>
                <b-row class="mt-2">
                    <b-col cols="12">
                        <wysiwyg ref="wyModal" v-model="commentText" :options="wysiwygOptions" />
                    </b-col>
                </b-row>
                <b-row class="mt-2 mr-0 justify-content-end" v-if="selectedMediaAdd && label && commentText">
                    <b-button variant="success" @click="createTemplateAndResetFields">Créer le template</b-button>
                </b-row>
            </b-tab>

            <b-tab title="Modifier">
                <b-form-group id="selectMedia" label="Listes des médias : " label-for="selectMedia">
                    <b-form-select id="selectMedia" v-model="selectedMedia" :options="availableMedias"></b-form-select>
                </b-form-group>
                <b-form-group v-if="selectedMedia" id="selectLabel" label="Listes des labels : "
                    label-for="selectLabel">
                    <b-form-select id="selectLabel" v-model="selectedLabel" :options="labelOptions"></b-form-select>
                </b-form-group>
                <b-row class="mt-2" v-if="selectedLabel">
                    <b-col cols="12">
                        <wysiwyg ref="wy" v-model="selectedLabel.comment" :options="wysiwygOptions" />
                    </b-col>
                </b-row>
                <b-row class="mt-2 mr-0 justify-content-end" v-if="selectedMedia && selectedLabel">
                    <b-button variant="success" @click="updateMediaAndResetFields">Valider la modification</b-button>
                </b-row>
            </b-tab>

            <b-tab title="Supprimer">
                <b-form-group id="selectMedia" label="Listes des médias : " label-for="selectMedia">
                    <b-form-select id="selectedMediaDelete" v-model="selectedMedia" :options="availableMedias"
                        required></b-form-select>
                </b-form-group>
                <b-form-group id="selectMedia" label="Listes des labels : " label-for="selectMedia"
                    v-if="selectedMedia">
                    <b-form-select id="selectMedia" v-model="selectedDeleteLabel" :options="deleteModalOptions"
                        required></b-form-select>
                </b-form-group>

                <b-col cols="12" class="text-right m-3">
                    <b-button v-if="selectedDeleteLabel !== ''" variant="success"
                        @click="deleteTemplateAndResetFields">Supprimer le template</b-button>
                </b-col>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    data() {
        return {
            selectedMedia: '',
            selectedMediaAdd: '',
            selectedDeleteLabel: '',
            commentText: '',
            deleteModalOptions: [],
            label: '',
            labelOptions: [],
            selectedLabel: '',
            selectedLabelComment: '',
        };
    },
    computed: {
        ...mapGetters({
            availableMedias: 'app/availableMedias',
            successToast: 'app/successToast',
            errorToast: 'app/errorToast',
        }),
        getDetails() {
            let currentMedia = this.availableMedias.find((media) => media.value === this.selectedMedia);
            return JSON.parse(currentMedia.details);
        },
        wysiwygOptions() {
            return {
                hideModules: {
                    headings: true,
                    link: true,
                    code: true,
                    image: true,
                    table: true,
                },
                customModules: [this.createSizeModules(0.8, 'small'), this.createSizeModules(1, 'normal'), this.createSizeModules(1.2, 'big')],
            };
        },
    },
    async mounted() {
        await this.getAvailableMedias();
    },

    watch: {
        selectedMediaDelete(newValue) {
            if (newValue) {
                this.getTemplateBytrigram();
            }
        },
        selectedMedia(newValue) {
            if (newValue) {
                this.getTemplateBytrigram();
            }
        },

        selectedMediaAdd(newValue) {
            if (newValue) {
                this.setDefaultCommentForSelectedMedia(newValue);
            }
        }
    },
    methods: {
        ...mapActions('app', ['getAvailableMedias', 'updateMediaMetricsTemplate', 'getTemplateBytrigram']),
        createSizeModules(size, icon) {
            return {
                title: 'smallSize',
                customAction(utils) {
                    const sel = utils.getHTMLOfSelection();
                    return [['insertHTML', `<span style="font-size: ${size}em;">${sel}</span>`, true]];
                },
                icon: icon,
            };
        },

        async setDefaultCommentForSelectedMedia(selectedMedia) {
            try {
                const templates = await this.$store.dispatch('app/getTradersTemplatesByTrigram', { tri: selectedMedia });

                const defaultTemplate = templates.data.find((template) => template.is_default === 1);

                if (defaultTemplate && defaultTemplate.comment) {
                    this.commentText = defaultTemplate.comment.text;

                    const quill = this.$refs.wyModal.quill;

                    if (quill) {
                        quill.setContents(defaultTemplate.comment.delta);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },

        async updateMediaAndResetFields() {
            try {
                let data = {
                    code: this.selectedLabel.code,
                    comment: { text: this.selectedLabel.comment },
                };

                const updateTemplateResponse = await this.$store.dispatch('app/updateTradersTemplate', data);

                if (updateTemplateResponse.status === 200) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Le template a été mit a jour avec succès`,
                            icon: 'smile',
                            variant: 'success',
                        },
                    });
                    this.resetFields();
                }
            } catch (error) {
                this.$toast(this.errorToast('Erreur lors de la modification du template'));
            }
        },

        resetFields() {
            this.label = '';
            this.commentText = '';
            this.selectedMediaAdd = '';
            this.selectedMedia = '';
            this.selectedMediaDelete = '';
            this.selectedDeleteLabel = '';
            this.selectedLabel = '';
        },

        async createTemplateAndResetFields() {
            try {
                const data = {
                    label: this.label,
                    media: this.selectedMediaAdd,
                    comment: {
                        text: this.commentText,
                    },
                };

                const createTemplateResponse = await this.$store.dispatch('app/createTraderTemplate', data);

                if (createTemplateResponse.status === 200) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Le template ${this.label} a été créé avec succès`,
                            icon: 'smile',
                            variant: 'success',
                        },
                    });
                    this.resetFields();
                }
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Une erreur est survenue pendant la création de votre template',
                        icon: 'exclamation-triangle',
                        variant: 'danger',
                    },
                });
            }
        },

        async getTemplateBytrigram() {
            try {
                const templates = await this.$store.dispatch('app/getTradersTemplatesByTrigram', { tri: this.selectedMedia });

                const filteredTemplates = templates.data
                    .filter((template) => template.is_default === 0)


                const deleteOptions = filteredTemplates.map((template) => ({
                    text: template.label,
                    value: template.code,
                }));

                this.deleteModalOptions = deleteOptions;

                this.labelOptions = templates.data.map((template) => ({
                    text: template.label,
                    value: { comment: template.comment.text, code: template.code },
                }));
            } catch (error) {
                console.log(error);
            }
        },

        async deleteTemplateAndResetFields() {
            try {
                const data = {
                    code: this.selectedDeleteLabel,
                };

                const deleteTemplateResponse = await this.$store.dispatch('app/deleteTradersTemplate', data);

                if (deleteTemplateResponse.status === 200) {
                    this.resetFields();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Le template ${this.label} a été supprimer avec succès`,
                            icon: 'smile',
                            variant: 'success',
                        },
                    });
                }
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Une erreur est survenue pendant la suppression de votre template',
                        icon: 'exclamation-triangle',
                        variant: 'danger',
                    },
                });
            }
        },
    },
};
</script>

<style>
.top-right-button {
    top: 1rem;
    right: 1rem;
    z-index: 10;
}
</style>
